<template>
  <div style="width: 315px" class="flex-y flex-1">
    <div class="electron-spacer" />
    <!-- Minimised sidebar -->
    <div
      class="minimise-width column flex-1 justify-between items-center"
      v-if="drawerLeft === 'mini'"
    >
      <!-- Top Options in mini sidebar -->
      <div class="app-not-draga">
        <!-- <div class="row q-my-xs">
          <q-btn
            dense
            :icon="$icons.mdiChevronDoubleRight"
            flat
            round
            :class="['text-theme-grey btn-hover']"
            @click.native.stop="toggleSidebar"
          >
            <q-tooltip content-class="app-text"> Show/Hide Sidebar </q-tooltip>
          </q-btn>
        </div> -->
        <div
          class="row items-center justify-center q-mt-md relative-position"
          :class="[drawerRight === 'notifications' && 'active-link']"
          @click="closeNotificationSidebar"
        >
          <div
            class="row items-center nav-sidebar-section-title cursor-pointer"
            @mouseover="notificationHover = true"
            @mouseleave="notificationHover = false"
          >
            <q-tooltip
              anchor="bottom right"
              self="top right"
              :offset="[100, 10]"
              >Your notifications</q-tooltip
            >
            <div class="new-notification">
              <q-icon
                size="18px"
                :name="
                  notificationHover || drawerRight === 'notifications'
                    ? $icons.mdiBell
                    : $icons.mdiBellOutline
                "
              />
            </div>
          </div>
          <q-icon
            :name="$icons.matFiberManualRecord"
            class="min-sidebar-notification-badge absolute"
            size="12px"
            v-if="totalNotifications"
          />
        </div>
        <div
          class="row items-center justify-center q-mt-md"
          :class="[
            $route.name === 'MemberView' &&
              isAuthUserMyTaskView &&
              'active-link',
          ]"
          @click="checkWorkAccess('MemberView')"
        >
          <div
            class="row items-center nav-sidebar-section-title cursor-pointer"
            @mouseover="myTaskHover = true"
            @mouseleave="myTaskHover = false"
          >
            <q-tooltip
              anchor="bottom right"
              self="top right"
              :offset="[100, 10]"
              >Tasks assigned to you</q-tooltip
            >
            <div>
              <q-icon
                :name="
                  myTaskHover || $route.name === 'MemberView'
                    ? $icons.mdiCheckBold
                    : $icons.mdiCheckOutline
                "
                size="20px"
              />
            </div>
          </div>
        </div>
        <div
          class="row items-center justify-center q-mt-md"
          :class="[$route.name === 'AdminView' && 'active-link']"
          v-if="isAdmin"
          @click="checkWorkAccess('AdminView')"
        >
          <div
            class="row items-center nav-sidebar-section-title cursor-pointer"
            @mouseover="myWorkHover = true"
            @mouseleave="myWorkHover = false"
          >
            <q-tooltip
              anchor="bottom right"
              self="top right"
              :offset="[100, 10]"
              >Team workload</q-tooltip
            >
            <div>
              <q-icon
                :name="
                  myWorkHover || $route.name === 'AdminView'
                    ? $icons.mdiAccountMultiple
                    : $icons.mdiAccountMultipleOutline
                "
                size="20px"
              />
            </div>
          </div>
        </div>
        <div
          class="row items-center justify-center q-mt-md"
          :class="[$route.name === 'SavedItemsView' && 'active-link']"
          @click="checkSavedItemAccess"
        >
          <div
            class="row items-center nav-sidebar-section-title cursor-pointer"
            @mouseover="savedItemsHover = true"
            @mouseleave="savedItemsHover = false"
          >
            <q-tooltip
              anchor="bottom right"
              self="top right"
              :offset="[100, 10]"
              >Items saved across workspace</q-tooltip
            >
            <div class="new-notification">
              <q-icon
                size="18px"
                :name="
                  savedItemsHover || $route.name === 'SavedItemsView'
                    ? $icons.matBookmark
                    : $icons.matBookmarkBorder
                "
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Profile Option in min sidebar -->
      <!-- <div class="row q-mb-md justify-center items-center">
        <div class="app-not-draga">
          <q-avatar
            size="42px"
            :color="!userProfilePhoto ? 'grey-4' : ''"
            :text-color="!userProfilePhoto ? 'grey-10' : ''"
          >
            <img
              :src="userProfilePhoto"
              :alt="nameInitials"
              v-if="userProfilePhoto"
            />
            <span class="text-avatar-style" v-else>
              {{ nameInitials }}
            </span>
            <q-menu content-class="overflow-auto sidebar-menu" auto-close>
              <q-list dense>
                <template v-for="(item, index) in toolbarItems">
                  <q-item
                    :key="index"
                    v-if="isVisible(item.roles)"
                    @click="handleFooterMenuAction(item.args[0])"
                    clickable
                  >
                    <q-item-section>
                      {{ item.title }}
                    </q-item-section>
                  </q-item>
                </template>
                <q-item
                  v-if="isVisible([1])"
                  clickable
                  @click="handleFooterMenuAction('importData')"
                >
                  <q-item-section>Import</q-item-section>
                </q-item>
                <q-item
                  v-if="isVisible([1])"
                  @click="handleFooterMenuAction('timeLog')"
                  clickable
                >
                  <q-item-section> View team-wide timesheet </q-item-section>
                </q-item>
                <q-separator v-if="isVisible([1, 2])" />
                <q-item
                  clickable
                  v-if="isVisible([1, 2, 3, 4])"
                  @click="handleFooterMenuAction('accountSetting')"
                >
                  <q-item-section>Change Password</q-item-section>
                </q-item>
                <q-item
                  clickable
                  v-if="isVisible([1, 2])"
                  @click="handleFooterMenuAction('administration')"
                >
                  <q-item-section>Billing</q-item-section>
                </q-item>
                <q-item
                  clickable
                  @click="handleFooterMenuAction('userSetting')"
                >
                  <q-item-section>Edit Profile</q-item-section>
                </q-item>
                <div class="text-left">
                  <div class="q-mb-sm">
                    <q-btn
                      label="Sign-in to another team"
                      class="signin-another-company"
                      align="left"
                      text-color="dark"
                      no-caps
                      @click="loginModel = !loginModel"
                      style="
                        width: 100%;
                        background: #f3f5f8 !important;
                        padding-left: 9px;
                      "
                    />
                  </div>
                </div>
                <q-item
                  clickable
                  v-if="isVisible([1, 2, 3, 4])"
                  @click="handleFooterMenuAction('notificationSetting')"
                >
                  <q-item-section>Notifications Settings</q-item-section>
                </q-item>
                <div
                  class="row q-py-sm q-px-md"
                  :key="key"
                  v-for="(company, key) in companies"
                >
                  <q-item-section avatar>
                    <div @click="switchCompany(key)" class="cursor-pointer">
                      <q-avatar size="40px" v-if="company.logo">
                        <img :src="company.logo" :alt="company.companyName" />
                      </q-avatar>
                      <q-avatar
                        size="40px"
                        color="blue-grey-4"
                        text-color="white"
                        class="text-bold"
                        font-size="18px"
                        v-else
                      >
                        {{ company.companyName | first2Char }}
                      </q-avatar>
                    </div>
                  </q-item-section>
                  <q-item-section>
                    <div
                      @click="switchCompany(key)"
                      class="cursor-pointer an-18 uppercase-text"
                    >
                      {{
                        company && company.companyName.length > 10
                          ? company.companyName.substring(0, 11) + "..."
                          : company.companyName
                      }}
                      <q-icon
                            name="fiber_manual_record"
                            v-if="
                              companyNotification[company.companyId] === true
                            "
                            color="pink"
                          />
                    </div>
                  </q-item-section>
                  <q-item-section class="q-pl-none" side>
                    <div v-if="key === activeCompany.companyUri">
                      <q-btn
                        :icon="$icons.matCheckCircle"
                        dense
                        size="md"
                        color="teal-14"
                        round
                        flat
                      />
                      <q-btn
                        v-if="isVisible([1])"
                        @click="handleFooterMenuAction('companysetting')"
                        :icon="$icons.mdiCircleEditOutline"
                        color="blue-grey-11"
                        round
                        flat
                        dense
                        size="md"
                      />
                      <q-btn
                        @click="signOutSpecificCompany(key)"
                        :icon="$icons.mdiExitToApp"
                        color="blue-grey-11"
                        round
                        flat
                        dense
                        size="md"
                      />
                    </div>
                    <div v-else>
                      <q-btn
                        @click="signOutSpecificCompany(key)"
                        :icon="$icons.mdiExitToApp"
                        color="blue-grey-11"
                        round
                        flat
                        dense
                        size="md"
                      />
                    </div>
                  </q-item-section>
                </div>
              </q-list>
            </q-menu>
          </q-avatar>
        </div>
      </div> -->
    </div>
    <!-- Expanded Sidebar -->
    <div class="flex-y flex-1" v-else>
      <!-- Activity Sction Start -->
      <div class="navigation-sidebar-section-hover" style="line-height: 1.7">
        <div
          class="icon-and-title q-mx-sm q-my-xs cursor-pointer"
          @click="closeNotificationSidebar"
          :class="[drawerRight === 'notifications' && 'active-link']"
        >
          <div
            class="row items-center nav-sidebar-section-title cursor-pointer q-mr-sm"
            @mouseover="notificationHover = true"
            @mouseleave="notificationHover = false"
          >
            <q-tooltip
              anchor="bottom right"
              self="top right"
              :offset="[100, 10]"
              >Your notifications</q-tooltip
            >
            <div class="new-notification q-mr-md">
              <q-icon size="18px" :name="$icons.mdiBellOutline" />
            </div>
            New
          </div>
          <div class="new-notification-count">
            <div
              class="circle-background row items-center justify-center"
              v-if="totalNotifications"
            >
              <div class="text">
                {{ totalNotifications > 9 ? "9+" : totalNotifications }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="icon-and-title q-mx-sm q-my-xs cursor-pointer"
          :class="[
            $route.name === 'MemberView' &&
              isAuthUserMyTaskView &&
              'active-link',
          ]"
          @click="checkWorkAccess('MemberView')"
        >
          <div
            class="row items-center nav-sidebar-section-title"
            @mouseover="myTaskHover = true"
            @mouseleave="myTaskHover = false"
          >
            <q-tooltip
              anchor="bottom right"
              self="top right"
              :offset="[100, 10]"
              >Tasks assigned to you</q-tooltip
            >
            <div class="q-mr-md">
              <q-icon :name="$icons.mdiCheckOutline" size="20px" />
            </div>
            <div>My tasks</div>
          </div>
        </div>
        <div
          class="icon-and-title q-mx-sm q-my-xs cursor-pointer"
          :class="[$route.name === 'AdminView' && 'active-link']"
          v-if="isAdmin"
          @click="checkWorkAccess('AdminView')"
        >
          <div
            class="row items-center nav-sidebar-section-title"
            @mouseover="myWorkHover = true"
            @mouseleave="myWorkHover = false"
          >
            <q-tooltip
              anchor="bottom right"
              self="top right"
              :offset="[100, 10]"
              >Team workload</q-tooltip
            >
            <div class="q-mr-md">
              <q-icon :name="$icons.mdiAccountMultipleOutline" size="20px" />
            </div>
            <div>My team</div>
          </div>
        </div>
        <div
          class="icon-and-title q-mx-sm q-my-xs cursor-pointer"
          :class="[$route.name === 'SavedItemsView' && 'active-link']"
          @click="checkSavedItemAccess"
        >
          <div
            class="row items-center nav-sidebar-section-title"
            @mouseover="savedItemsHover = true"
            @mouseleave="savedItemsHover = false"
          >
            <q-tooltip
              anchor="bottom right"
              self="top right"
              :offset="[100, 10]"
              >Items saved across workspaces</q-tooltip
            >
            <div class="saved-items q-mr-md">
              <q-icon :name="$icons.matBookmarkBorder" size="18px" />
            </div>
            <div>Saved items</div>
          </div>
        </div>
      </div>

      <!-- Workspace Listing Start -->
      <div
        class="flex-1 q-mb-xs scroll-y scroll-dark scroll-overlay scrollbar-hover"
        style="overflow-x: hidden"
        ref="workspaceSidebarList"
        id="sidebar-scroll-container"
        v-scroll-hover
      >
        <q-list
          class="relative-position navigation-sidebar-section-hover"
          style="padding-bottom: 12px"
        >
          <q-expansion-item
            class="list-tile-headv2"
            :style="menuItems.workspace.model && { 'margin-bottom': '-11px' }"
            :value="menuItems.workspace.model"
            @click="menuItems.workspace.model = !menuItems.workspace.model"
          >
            <template v-slot:header>
              <div class="row group-hover q-mx-sm q-mb-md" style="width: 100%">
                <div
                  class="row items-center flex-no-wrap justift-between workspace-type-title"
                  style="width: 100%"
                >
                  <div class="flex-1">
                    Workspaces
                    <q-item-label
                      class="absolute q-mt-xs"
                      style="color: #8d87aa !important; margin-top: 8px"
                      v-if="!menuItems.workspace.model"
                      caption
                    >
                      See More
                    </q-item-label>
                  </div>
                  <div>
                    <q-btn
                      size="14px"
                      dense
                      :icon="$icons.matSearch"
                      flat
                      round
                      padding="0"
                      class="text-theme-grey btn-hover"
                      @click.stop="() => toggleSearch()"
                    >
                      <q-tooltip> Filter Workspaces </q-tooltip>
                    </q-btn>
                    <q-btn
                      size="14px"
                      class="btn-hover text-theme-grey"
                      v-if="canCJSPublic"
                      :icon="$icons.mdiPlusThick"
                      round
                      dense
                      flat
                      padding="0"
                      @click.stop
                    >
                      <q-tooltip anchor="top middle" self="bottom middle">
                        Create workspaces & folders
                      </q-tooltip>
                      <q-menu auto-close>
                        <q-list>
                          <q-item
                            class="app-text-normal"
                            clickable
                            @click="
                              handleCreateWorkspace(
                                true,
                                canCreatePrivateWorkspace ? 'private' : 'public'
                              )
                            "
                          >
                            <q-item-section> Create workspace </q-item-section>
                          </q-item>
                          <q-item
                            clickable
                            @click="joinPublicDialogHandler()"
                            class="app-text-normal"
                          >
                            <q-item-section> Browse workspaces </q-item-section>
                          </q-item>
                          <q-separator />
                          <q-item
                            clickable
                            @click="checkFolderAccess"
                            class="app-text-normal"
                          >
                            <q-item-section
                              class="q-pr-none"
                              style="min-width: 35px !important"
                              avatar
                            >
                              <q-icon :name="$icons.matFolder" />
                            </q-item-section>
                            <q-item-section> Create folder </q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-btn>
                  </div>
                </div>
                <div
                  class="row q-my-xs flex-1 q-px-xs justify-center"
                  v-if="searchInWorkspace"
                  @click.stop
                >
                  <q-input
                    v-model="search.text"
                    placeholder="Jump to..."
                    dense
                    borderless
                    autofocus
                    input-class="text-theme-grey "
                    class="full-width q-px-xs bg-white rounded-borders"
                    style="box-shadow: rgb(149 157 165 / 20%) 1px 1px 3px"
                    @click.stop
                  >
                    <template v-slot:prepend>
                      <q-icon
                        :name="$icons.matFilterList"
                        class="text-theme-grey"
                        size="sm"
                        @click.stop
                      />
                    </template>
                    <template v-slot:append>
                      <q-btn
                        dense
                        flat
                        rounded
                        padding="0"
                        size="md"
                        :icon="$icons.matClose"
                        @click.stop="clearSearchHandler"
                        class="text-theme-grey"
                      />
                    </template>
                  </q-input>
                </div>
              </div>
            </template>
            <!-- Preferred -->
            <q-item
              v-for="(child, i) in preferredWorkspaces"
              :key="'preferred' + i"
              :class="[
                'q-pa-none hover-list q-mx-sm q-mb-xs',
                returnCurrentWorkspaceId === child.id &&
                  'router-link-active active-workspace-item',
              ]"
            >
              <div
                :class="{
                  'q-mb-sm': returnCurrentWorkspaceId === child.id,
                }"
              >
                <workspace-navigation
                  :onlineMembers="onlineMembers"
                  :workspace="child"
                  :folders="folders"
                  :returnCurrentWorkspaceId="returnCurrentWorkspaceId"
                  :index="i"
                  :isPreferred="true"
                />
              </div>
            </q-item>
            <!-- Public -->
            <q-item
              v-for="(child, i) in sortedWorkspaces"
              :key="'public' + i"
              :class="[
                'q-pa-none hover-list q-mx-sm q-mb-xs',
                returnCurrentWorkspaceId === child.id &&
                  'router-link-active active-workspace-item',
              ]"
            >
              <div
                :class="{
                  'q-mb-sm': returnCurrentWorkspaceId === child.id,
                }"
              >
                <workspace-navigation
                  :workspace="child"
                  :folders="folders"
                  :returnCurrentWorkspaceId="returnCurrentWorkspaceId"
                  :index="i"
                />
              </div>
            </q-item>
            <q-item
              class="q-pa-none q-mb-xs"
              style="min-height: 36px !important"
              v-for="folder in folders"
              :key="folder.id"
            >
              <workspace-folder
                :onlineMembers="onlineMembers"
                :folders="folders"
                :folder="folder"
                :workspaces="folderWorkpaces[folder.id]"
                :allworkspaces="workspaces"
                :renameFolder="renameFolderHandler"
                :confirmDeleteFolder="confirmDeleteFolder"
              />
            </q-item>
          </q-expansion-item>
        </q-list>
        <!-- People -->
        <q-list class="q-mt-sm q-pb-md navigation-sidebar-section-hover">
          <q-expansion-item
            :value="menuItems.people.model"
            @click="menuItems.people.model = !menuItems.people.model"
            class="list-tile-headv2"
            style="margin-bottom: -6px"
            header-style="padding-right: 9px;"
          >
            <template v-slot:header>
              <div
                class="group-hover row items-center justify-between q-mx-sm q-mb-md"
                style="width: 100%"
              >
                <div class="workspace-type-title">
                  <div class="flex-1">
                    {{ menuItems.people.text }}
                    <q-item-label
                      class="absolute q-mt-xs"
                      style="color: #8d87aa !important"
                      v-if="!menuItems.people.model"
                      caption
                    >
                      See More
                    </q-item-label>
                  </div>
                </div>
                <div>
                  <q-btn
                    class="text-theme-grey btn-hover"
                    :icon="$icons.mdiPlusThick"
                    round
                    dense
                    flat
                    @click.stop="openpeopleWorkspaceDialog"
                  >
                    <q-tooltip> Direct message </q-tooltip>
                  </q-btn>
                </div>
              </div>
            </template>
            <q-item
              v-for="(child, i) in peopleWorkspaces"
              :key="i"
              :class="[
                'q-pa-none hover-list q-mx-sm q-mb-xs',
                returnCurrentWorkspaceId === child.id &&
                  'router-link-active active-workspace-item',
              ]"
            >
              <div
                :class="{
                  'q-mb-sm': returnCurrentWorkspaceId === child.id,
                }"
              >
                <workspace-navigation
                  :workspace="child"
                  :onlineMembers="onlineMembers"
                  :folders="folders"
                  :returnCurrentWorkspaceId="returnCurrentWorkspaceId"
                />
              </div>
            </q-item>
          </q-expansion-item>
        </q-list>
      </div>
      <q-separator style="background: #f6f6f8" />
      <!-- Timer -->
      <div
        v-if="activeTimelog && user.id === activeTimelog.owner.id"
        class="workspace-active-timer q-mx-sm q-pa-md q-px-sm row justify-between flex-no-wrap items-center q-mb-sm"
      >
        <timer
          :isWorkspaceTimer="true"
          :startTime="activeTimelog.start_date"
          :stopTimer="stopTimerHandler"
          :cardTask="activeTimerTask"
          :openTaskDetail="openTaskDetail"
          :openTimeLogDialog="openTimeLogDialog"
        />
      </div>
      <!-- Upgrade Plan Banner -->
      <!-- <div
        v-if="checkBanner"
        class="workspace-plan q-pa-sm row justify-between flex-no-wrap items-center"
      >
        <span class="upgrade" clickable @click="upgradePlan.flag = true">
          Upgrade today
        </span>
      </div> -->
      <!-- Bottom Menu -->
      <div
        class="row items-center justify-between q-my-sm q-px-md"
      >
        <!-- <div class="row justify-between items-center"> -->
        <!-- Profile Menu -->
        <!-- <span class="cursor-pointer">
            <div>
              <q-avatar
                size="42px"
                :color="!userProfilePhoto ? 'grey-4' : ''"
                :text-color="!userProfilePhoto ? 'grey-10' : ''"
              >
                <img
                  :src="userProfilePhoto"
                  :alt="nameInitials"
                  v-if="userProfilePhoto"
                />
                <span class="text-avatar-style" v-else>
                  {{ nameInitials }}
                </span>
                <q-tooltip content-class="tooltip-q">
                  {{ fullName | capitalize }} | {{ activeCompany.companyName }}
                </q-tooltip>
              </q-avatar>
              <q-menu content-class="overflow-auto sidebar-menu" auto-close>
                <q-list dense>
                  <template v-for="(item, index) in toolbarItems">
                    <q-item
                      :key="index"
                      v-if="isVisible(item.roles)"
                      @click="handleFooterMenuAction(item.args[0])"
                      clickable
                    >
                      <q-item-section>
                        {{ item.title }}
                      </q-item-section>
                    </q-item>
                  </template>
                  <q-item
                    v-if="isVisible([1])"
                    clickable
                    @click="handleFooterMenuAction('importData')"
                  >
                    <q-item-section>Import</q-item-section>
                  </q-item>
                  <q-item
                    v-if="isVisible([1])"
                    @click="handleFooterMenuAction('timeLog')"
                    clickable
                  >
                    <q-item-section> View team-wide timesheet </q-item-section>
                  </q-item>
                  <q-separator v-if="isVisible([1, 2])" />
                  <q-item
                    clickable
                    v-if="isVisible([1, 2, 3, 4])"
                    @click="handleFooterMenuAction('accountSetting')"
                  >
                    <q-item-section>Change Password</q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    v-if="isVisible([1, 2])"
                    @click="handleFooterMenuAction('administration')"
                  >
                    <q-item-section>Billing</q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    @click="handleFooterMenuAction('userSetting')"
                  >
                    <q-item-section>Edit Profile</q-item-section>
                  </q-item>
                  <div class="text-left">
                    <div class="q-mb-sm">
                      <q-btn
                        label="Sign-in to another team"
                        class="signin-another-company"
                        align="left"
                        text-color="dark"
                        no-caps
                        @click="loginModel = !loginModel"
                        style="
                          width: 100%;
                          background: #f3f5f8 !important;
                          padding-left: 9px;
                        "
                      />
                    </div>
                  </div>
                  <q-item
                    clickable
                    v-if="isVisible([1, 2, 3, 4])"
                    @click="handleFooterMenuAction('notificationSetting')"
                  >
                    <q-item-section>Notifications Settings</q-item-section>
                  </q-item>
                  <div
                    class="row q-py-sm q-px-md"
                    :key="key"
                    v-for="(company, key) in companies"
                  >
                    <q-item-section avatar>
                      <div @click="switchCompany(key)" class="cursor-pointer">
                        <q-avatar size="40px" v-if="company.logo">
                          <img :src="company.logo" :alt="company.companyName" />
                        </q-avatar>
                        <q-avatar
                          size="40px"
                          color="blue-grey-4"
                          text-color="white"
                          class="text-bold"
                          font-size="18px"
                          v-else
                        >
                          {{ company.companyName | first2Char }}
                        </q-avatar>
                      </div>
                    </q-item-section>
                    <q-item-section>
                      <div
                        @click="switchCompany(key)"
                        class="cursor-pointer an-18 uppercase-text"
                      >
                        {{
                          company && company.companyName.length > 10
                            ? company.companyName.substring(0, 11) + "..."
                            : company.companyName
                        }}
                        <q-icon
                            name="fiber_manual_record"
                            v-if="
                              companyNotification[company.companyId] === true
                            "
                            color="pink"
                          />
                      </div>
                    </q-item-section>
                    <q-item-section class="q-pl-none" side>
                      <div v-if="key === activeCompany.companyUri">
                        <q-btn
                          :icon="$icons.matCheckCircle"
                          dense
                          size="md"
                          color="teal-14"
                          round
                          flat
                        />
                        <q-btn
                          v-if="isVisible([1])"
                          @click="handleFooterMenuAction('companysetting')"
                          :icon="$icons.mdiCircleEditOutline"
                          color="blue-grey-11"
                          round
                          flat
                          dense
                          size="md"
                        />
                        <q-btn
                          @click="signOutSpecificCompany(key)"
                          :icon="$icons.mdiExitToApp"
                          color="blue-grey-11"
                          round
                          flat
                          dense
                          size="md"
                        />
                      </div>
                      <div v-else>
                        <q-btn
                          @click="signOutSpecificCompany(key)"
                          :icon="$icons.mdiExitToApp"
                          color="blue-grey-11"
                          round
                          flat
                          dense
                          size="md"
                        />
                      </div>
                    </q-item-section>
                  </div>
                </q-list>
              </q-menu>
            </div>
          </span> -->
        <!-- <div class=""> -->
        <button
          v-if="
            canInviteMemberToPublicWorkspace ||
            canInviteMemberToPrivateWorkspace
          "
          @click="invitepeopleDialog = !invitepeopleDialog"
          class="add-people btn-hover"
        >
          <span class="q-mr-xs">
            <q-icon size="19.5px" :name="$icons.matPersonAdd" />
          </span>
          <span> Invite </span>
          <q-tooltip> Invite new people to {{company.name}} </q-tooltip>
          <!-- <q-menu auto-close>
                <q-list>
                  <q-item
                    @click="invitepeopleDialog = !invitepeopleDialog"
                    style="min-height:24px"
                    clickable
                  >
                    <q-item-section>
                      Invite people
                    </q-item-section>
                  </q-item>
                  <q-item
                    @click="openpeopleWorkspaceDialog"
                    style="min-height:24px"
                    clickable
                  >
                    <q-item-section>
                      Direct message
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu> -->
        </button>
        <q-btn class="sidebar-upgrade-btn" v-if="checkBanner">
          <!-- <q-icon :name="$icons.matNotificationsOff" /> -->
          <img
            class="image q-mr-xs"
            src="/static/images/logo/heycollab_small.svg"
          />
          <span class="upgrade" clickable @click="upgradePlan.flag = true">
            Upgrade
          </span>
        </q-btn>
        <!-- <q-btn
              :icon="$icons.mdiHelp"
              round
              size="sm"
              class="learn-more q-ml-xs"
              @click="routeTo('LearnView')"
            >
              <q-tooltip content-class="app-text-normal">Learn more</q-tooltip>
            </q-btn> -->
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <!-- Sidebar Content End -->
    <workspace-create-dialog
      v-if="createWorkspace.show"
      v-model="createWorkspace.show"
      @success="createWorkspace.show = false"
      :createType="createWorkspace.type"
      :members="companyMembers"
    />
    <join-public-workspace-dialog
      v-model="joinPublicDialog"
      v-if="joinPublicDialog"
      @success="joinPublicSuccessHandler"
    ></join-public-workspace-dialog>
    <create-people-workspace-dialog
      v-if="peopleWorkspaceDialog"
      v-model="peopleWorkspaceDialog"
      @success="createPeopleSuccessHandler"
      :members="companyMembers"
    />
    <invite-people-dialog
      v-if="invitepeopleDialog"
      v-model="invitepeopleDialog"
      @close="invitepeopleDialog = !invitepeopleDialog"
      @success="successHandler"
    />
    <signin-dialog
      v-model="loginModel"
      v-if="loginModel"
      @success="handleLoginSuccess"
      @companyExisted="handleLoggedInCompany"
      @changePassword="handleChangePassword"
      @forgetCompanyName="forgetCompanyHandler"
      @showSingInHandler="showSingInHandler"
    />
    <stop-timer-confirmation-dialog
      v-if="stopTimerConfirmation.flag"
      v-model="stopTimerConfirmation.flag"
      :stopTimer="stopTimer"
      :timelogId="stopTimerConfirmation.id"
      @closeDialog="
        stopTimerConfirmation.flag = false;
        stopTimerConfirmation.id = null;
      "
    />
    <create-folder-dialog
      v-if="createFolderDialog"
      v-model="createFolderDialog"
      :renameFolder="renameFolder"
      :user="user"
      @closeDialog="closeCreateFolder"
    />
    <div v-if="deleteFolder.flag">
      <confirm-dialog
        v-model="deleteFolder.flag"
        :title="confirmDeleteFolderTitle"
        :question="
          deleteFolder.deleteWorkspace
            ? `Deleting this folder will also delete all the workspaces in it.`
            : `Are you sure you want to delete this Folder?`
        "
        cancleText="Cancel"
        successText="Delete"
        :cancleMethod="
          () => {
            deleteFolder.flag = false;
            deleteFolder.id = null;
          }
        "
        :successMethod="deleteFolderHandler"
        :loading="deleteFolderLoader"
      />
    </div>
    <!-- <footer-menu-dialog
      @signOut="signOutSpecificCompany"
      v-if="footerMenuDialog.show"
      :currentWorkspaceId="currentWorkspaceId"
      :currentWorkspaceObj="currentWorkspaceObj"
      :modalDialogObject="modalDialog"
      :footerMenuDialog="footerMenuDialog"
      @closeDialog="closeFooterMenuDialog"
      @navigateTo="navigateTo"
      :isVisible="isVisible"
    /> -->
    <upgrade-plans-dialog
      v-if="upgradePlan.flag"
      v-model="upgradePlan.flag"
      :closeDialog="closeUpgradePlanDialog"
      :currentWorkspaceId="currentWorkspaceId"
      :currentCompany="company"
      :currentCompanyToken="currentCompany.accessToken"
    />
  </div>
</template>

<script>
import get from "lodash/get";
import set from "lodash/set";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import groupBy from "lodash/groupBy";

import { mapGetters } from "vuex";
import { resetState } from "@/store";
import { exportTimeLogs, getChannelList } from "@/services/auth";
import getUnixTime from "date-fns/getUnixTime";
//Mixins
import UserScope from "@/mixins/UserScope";
import ManageDialogMixin from "@/mixins/SidebarMixins/ManageDialogMixin";
import BotMessenger from "@/mixins/BotMessenger";
import SignInToAnotherCompanyMixin from "@/mixins/SidebarMixins/SignInToAnotherCompanyMixin";
import BoardsMethodMixin from "@/mixins/BoardsMethodMixin";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";

//Components
import WorkspaceNavigation from "@/views/WorkspaceView/NavigationSidebar/WorkspaceNavigation";
import WorkspaceCreateDialog from "@/components/Dialogs/WorkspaceCreateDialog";
import JoinPublicWorkspaceDialog from "@/components/Dialogs/JoinPublicWorkspaceDialog";
import CreatePeopleWorkspaceDialog from "@/components/Dialogs/CreatePeopleWorkspaceDialog";
import InvitePeopleDialog from "@/components/Dialogs/InvitePeopleDialog";
import SigninDialog from "@/components/Dialogs/SigninDialog";
import Timer from "@/components/Timer";
import StopTimerConfirmationDialog from "@/components/Dialogs/StopTimerConfirmationDialog";
import CreateFolderDialog from "@/components/Dialogs/CreateFolderDialog";
import WorkspaceFolder from "@/views/WorkspaceView/NavigationSidebar/WorkspaceFolder";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
// import FooterMenuDialog from "@/components/Dialogs/FooterMenuDialog";
import UpgradePlansDialog from "@/components/Dialogs/UpgradePlansDialog";
import { getCustomerPortalUrl } from "@/services/auth";

import {
  UpdateNotificationsMutation,
  UpdateTaskTimeLogMutation,
  UpdateCompanySettingsMutation,
  DeleteFolderMutation,
  PlanQuery,
  FeaturesQuery,
  CompanyQuery,
  EditProfileMutation,
} from "@/gql";
// import VideoDialog from "@/components/VuePlayer/VideoDialog";

export default {
  name: "NavigationSidebar",
  props: [
    "drawerLeft",
    "toggleDrawerLeft",
    "toggleDrawerRight",
    "unsubscribeAllChannel",
    "drawerRight",
    "footerMenuDialog",
  ],
  mixins: [
    UserScope,
    ManageDialogMixin,
    BotMessenger,
    SignInToAnotherCompanyMixin,
    BoardsMethodMixin,
    MediaMethodMixin,
  ],
  components: {
    WorkspaceNavigation,
    WorkspaceCreateDialog,
    JoinPublicWorkspaceDialog,
    CreatePeopleWorkspaceDialog,
    InvitePeopleDialog,
    SigninDialog,
    Timer,
    StopTimerConfirmationDialog,
    CreateFolderDialog,
    WorkspaceFolder,
    ConfirmDialog,
    // FooterMenuDialog,
    UpgradePlansDialog,

    // VideoDialog,
  },
  api: {
    company: {
      cacheKey: "CompanyQuery",
      defaultValue: null,
    },
    plan: {
      cacheKey: "PlanQuery",
      defaultValue: null,
    },
    members: {
      cacheKey: "MembersQuery",
      defaultValue: null,
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    folder: {
      cacheKey: "FoldersQuery",
      defaultValue: null,
    },
    board: {
      defaultValue: null,
      cacheKey() {
        return `BoardQuery:${this.currentWorkspaceObj.boards[0].id}`;
      },
    },
    reactions: {
      cacheKey: "ReactionsQuery",
      defaultValue: null,
    },
    taskLabels: {
      cacheKey: "TaskLabelsQuery",
    },
    workspaceMembers: {
      defaultValue: "",
      cacheKey() {
        return `WorkspaceMembersQuery:${this.currentWorkspaceObj.id}`;
      },
    },
    media: {
      defaultValue: [],
      cacheKey() {
        return `MediaQuery:${this.currentWorkspaceObj.id}`;
      },
    },
  },
  beforeCreate() {
    this.$eventBus.$off("send_invitation");
  },
  beforeDestroy() {
    this.$eventBus.$off("send_invitation");
  },
  mounted() {
    this.findCompany();
    this.getChannelList();
    // if (this.company) {
    //   if (
    //     this.companies[this.ownCompany].companyName !== this.company.name ||
    //     this.companies[this.ownCompany].logo !== this.company.logo
    //   ) {
    //     console.log(this.company.name);
    //     this.companies[this.ownCompany].companyName = this.company.name;
    //     this.companies[this.ownCompany].logo = this.company.logo;
    //     this.$store.dispatch("resetCompanies", this.companies);
    //   }
    //   // this.subscriptionData()
    // }
  },
  created() {
    // TOOLBAR IMPORT
    this.$eventBus.$on("send_invitation", this.handelSettingChanges);
  },
  data() {
    return {
      searchInWorkspace: false,
      search: {
        text: "",
      },
      createWorkspace: {
        show: false,
        type: "public",
      },
      stopTimerConfirmation: {
        flag: false,
        id: null,
      },
      createFolderDialog: false,
      targetTooltip: true,
      joinPublicDialog: false,
      peopleWorkspaceDialog: false,
      planDetailDialog: false,
      invitepeopleDialog: false,
      menuItems: {
        workspace: {
          model: true,
        },
        people: {
          model: true,
          symbol: this.$icons.matPeople,
          icon: "arrow_drop_up",
          "icon-alt": "arrow_drop_down",
          text: "Direct message",
        },
      },
      timelogs: {
        flag: false,
      },
      companyTimelogs: {
        flag: false,
      },
      deleteFolder: {
        flag: false,
        id: null,
        deleteWorkspace: false,
      },
      deleteFolderLoader: false,
      renameFolder: null,
      confirmDeleteFolderTitle: null,
      upgradePlan: {
        flag: false,
      },
      isWindowResized: false,
      showCreateFolder: false,
      planeDescription: null,
      planeFile: null,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
      notificationHover: false,
      myWorkHover: false,
      myTaskHover: false,
      savedItemsHover: false,
    };
  },
  methods: {
    async closeNotificationSidebar() {
      this.toggleDrawerRight("notifications");
      if (!this.$store.getters.drawerState) {
        const query = this.$api.getQuery("NotificationsQuery");
        let notification_ids;
        if (query.data) {
          notification_ids = query.data.notifications
            .filter((n) => !n.read)
            .map((n) => {
              n.read = true;
              return n.id;
            });
          if (notification_ids.length) {
            const variables = {
              notification_ids: notification_ids,
              read: true,
            };
            await this.$api.mutate({
              mutation: UpdateNotificationsMutation,
              variables,
            });
          }
        }
      }
    },
    async doNotDisturbClickEVent() {
      let variables = {
        first: this.user.first,
        last: this.user.last,
        username: this.user.username,
        lang: this.user.lang,
        web_notification: !this.user.web_notification,
      };
      await this.$api.mutate({
        mutation: EditProfileMutation,
        variables,
      });
    },
    openCurrentUrl() {
      window.open(
        process.env.VUE_APP_APPBASE_URL + this.$route.fullPath,
        "heycollab",
        "width=900,height=600,scrollbars=yes,top=100,left=400,resizable = yes"
      );
    },
    async getChannelList() {
      if (this.$route.query.code && this.$route.query.state == "import-data") {
        await getChannelList(
          {
            code: this.$route.query.code,
          },
          this.company.accessToken
        );
      }
    },
    async findCompany() {
      const findCompanyData = await this.$api.query({
        query: CompanyQuery,
        cacheKey: "CompanyQuery",
      });
      if (findCompanyData) {
        this.subscriptionData();
      }
    },
    //TODO: change method name to something meaningful
    checkWorkAccess(route) {
      let eligible = this.$checkEligiblity("my_work");
      if (eligible) {
        if (route === "MemberView") {
          this.$router.push({
            name: route,
            params: {
              userId: this.user.id,
            },
          });
        } else {
          this.$router.push({ name: route });
        }
      }
    },
    checkFolderAccess() {
      let eligible = this.$checkEligiblity("workspace_folders");
      if (eligible) {
        this.openCreateFolder();
      }
    },
    checkSavedItemAccess() {
      let eligible = this.$checkEligiblity("saved_items");
      if (eligible) {
        this.routeTo("SavedItemsView");
      }
    },
    async subscriptionData() {
      const variables = {
        plan_id: this.company.subscription.plan_id || 1,
      };
      const response = await this.$api.query({
        query: PlanQuery,
        cacheKey: "PlanQuery",
        variables,
      });
      const allFeatures = await this.$api.query({
        query: FeaturesQuery,
        cacheKey: "FeaturesQuery",
      });
      this.$store.dispatch("subscriptionPlanData", response.data);
      this.$store.dispatch("allFeatureList", allFeatures);
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/579275845",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
      this.$mixpanelEvent("sidebar-help", {});
    },
    closePlanExpiredDialog() {
      this.showCreateFolder = false;
    },
    openCreateFolder() {
      if (
        this.currentCompany.status &&
        this.currentCompany.status.subscriptionOver
      ) {
        this.planeDescription =
          "Create folders organize your workspaces, people and departments.";
        this.planeFile = "https://player.vimeo.com/video/587230669";
        this.showCreateFolder = true;
      } else {
        this.createFolderDialog = true;
      }
    },
    closeUpgradePlanDialog() {
      this.upgradePlan.flag = false;
    },
    async generateStripeCustomerPortalLink() {
      let res = await getCustomerPortalUrl(
        this.currentCompany.accessToken,
        this.returnCurrentWorkspaceId
      );
      window.location = res.url;
    },
    handleFooterMenuAction(item) {
      this.footerMenuDialog.selectedMenuItem = item;
      this.footerMenuDialog.show = true;
    },
    renameFolderHandler(folder) {
      this.renameFolder = folder;
      this.createFolderDialog = true;
    },
    confirmDeleteFolder(folderId, deleteWorkspace) {
      this.deleteFolder.flag = true;
      this.confirmDeleteFolderTitle = deleteWorkspace
        ? "Delete folder and workspaces?"
        : "Delete folder?";
      this.deleteFolder.deleteWorkspace = deleteWorkspace;
      this.deleteFolder.id = folderId;
    },
    async deleteFolderHandler() {
      this.deleteFolderLoader = true;
      const folderQuery = this.$api.getQuery("FoldersQuery");

      if (this.deleteFolder.deleteWorkspace) {
        const worskpaceQuery = this.$api.getQuery("Workspaces2Query");
        worskpaceQuery.data.workspaces2 =
          worskpaceQuery.data.workspaces2.filter((w) => {
            if (
              w.id === this.currentWorkspaceId &&
              w.folder[0] &&
              w.folder[0].id === this.deleteFolder.id
            ) {
              this.$router.push({
                name: "MessagingView",
                params: {
                  company: this.activeCompany.companyUri,
                  workspace: 1,
                },
              });
            }
            if (w.folder[0] && w.folder[0].id === this.deleteFolder.id) {
              return false;
            }
            return w;
          });
      } else {
        const worskpaceQuery = this.$api.getQuery("Workspaces2Query");
        worskpaceQuery.data.workspaces2 = worskpaceQuery.data.workspaces2.map(
          (w) => {
            if (w.folder[0] && w.folder[0].id === this.deleteFolder.id) {
              w.folder = [];
            }
            return w;
          }
        );
      }
      folderQuery.data.folder = folderQuery.data.folder.filter(
        (f) => f.id !== this.deleteFolder.id
      );
      await this.$api.mutate({
        mutation: DeleteFolderMutation,
        variables: {
          id: this.deleteFolder.id,
          delete_workspaces: this.deleteFolder.deleteWorkspace,
        },
      });
      this.deleteFolderLoader = false;
      this.deleteFolder.flag = false;
      this.deleteFolder.id = null;
    },
    closeCreateFolder() {
      this.createFolderDialog = false;
      this.renameFolder = null;
    },
    routeTo(name) {
      if (this.$route.name !== name) {
        this.$router.push({ name });
        if (name === "LearnView") {
          this.$mixpanelEvent("learn", {});
        }
      }
    },
    openTaskDetail() {
      const obj = {
        task: {
          boardId: this.activeTimerTask.board_id, //boardId is passed to differentiate navigationsidebar click
          ...this.activeTimerTask,
        },
        isOpen: null,
      };
      this.$eventBus.$emit("openTaskDialog", obj);
    },
    closeTaskDialog() {
      this.$eventBus.$emit("closeTaskDialog");
    },
    closeTimeLogDialog() {
      this.timelogs.flag = false;
    },
    closeCompanyTimeLogDialog() {
      this.companyTimelogs.flag = false;
    },
    openTimeLogDialog() {
      this.timelogs.flag = true;
    },
    stopTimerHandler(data) {
      this.stopTimerConfirmation.flag = true;
      this.stopTimerConfirmation.id = data.id;
    },
    async stopTimer(timelogId) {
      const variables = {
        end_date: getUnixTime(new Date()),
        id: timelogId,
        is_start: false,
      };
      const newTaskTimeLog = await this.$api.mutate({
        mutation: UpdateTaskTimeLogMutation,
        variables,
      });
      const taskEntity = this.$api.getEntity("task", this.activeTaskId);
      taskEntity.activetimelog = null;

      let objIndex = taskEntity.timelogs.findIndex(
        (obj) => obj.id == timelogId
      );
      taskEntity.timelogs[objIndex] = newTaskTimeLog.data.updateTaskTimeLog;

      const companyEntity = this.$api.getEntity(
        "company",
        this.currentCompany.companyId
      );
      companyEntity.start_task_id = null;
      companyEntity.start_time_log_id = null;
      const companyVariables = {
        name: companyEntity.name,
        start_task_id: null,
        start_time_log_id: null,
      };
      await this.$api.mutate({
        mutation: UpdateCompanySettingsMutation,
        variables: companyVariables,
      });
    },
    expandSidebar() {
      if (this.drawerLeft === "mini") {
        this.toggleSidebar();
      }
    },
    async toggleSidebar() {
      await this.toggleDrawerLeft();
      //   const ps = this.$refs.workspaceSidebarList;
      for (const property in this.menuItems) {
        this.menuItems[property].model =
          this.drawerLeft === "mini" ? false : true;
      }
      //   if (ps) ps.scrollTop = 0;
    },
    toggleSearch() {
      this.searchInWorkspace = !this.searchInWorkspace;
      this.search.text = "";
    },
    clearSearchHandler() {
      this.searchInWorkspace = false;
      this.search.text = "";
    },
    handleCreateWorkspace(show = true, type = "public") {
      this.fab = false;
      this.createWorkspace = {
        show,
        type,
      };
    },
    successHandler(data) {
      this.invitepeopleDialog = !this.invitepeopleDialog;
      const verbiage = data.emails.length > 1 ? "people" : "person";
      this.$q.notify({
        classes: "success-notification",
        position: "top-right",
        message: `You have invited ${data.emails.length} ${verbiage} to ${this.activeCompany.companyName}`,
        timeout: 2500,
        icon: this.$icons.matAnnouncement,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
    },
    handelSettingChanges(data) {
      if (data.flag) set(this, data.flag, false);

      if (data && data.message) {
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: data.message,
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    isVisible(rolesArray) {
      if (rolesArray.indexOf(this.currentRole) > -1) {
        return true;
      } else {
        return false;
      }
    },
    closeModal(modalName) {
      const handler = (e) => {
        if (e.keyCode === 27) {
          set(this, modalName, false);
          window.removeEventListener("keydown", handler);
        }
      };
      if (get(this, modalName)) {
        window.addEventListener("keydown", handler);
      }
    },
    async joinPublicSuccessHandler(data) {
      this.joinPublicDialog = false;
      await this.joinPublicWorkspaceAnnouncement(data); // mixin call
      this.$router.push({
        name: "MessagingView",
        params: {
          company: this.activeCompany.companyUri,
          workspace: data.workspaceId,
        },
      });
    },
    createPeopleSuccessHandler(workspace) {
      this.peopleWorkspaceDialog = false;
      this.$router.push({
        name: "MessagingView",
        params: {
          company: this.activeCompany.companyUri,
          workspace,
        },
      });
    },
    joinPublicDialogHandler() {
      this.joinPublicDialog = true;
      this.fab = false;
    },
    async openpeopleWorkspaceDialog() {
      this.peopleWorkspaceDialog = true;
    },
    async switchCompany(companyKey) {
      this.$store.dispatch("setLastVisit", this.$route.path);
      // this.companies[companyKey].lastVisit
      //   ? this.companies[companyKey].lastVisit
      //   : `/companies/${companyKey}/workspaces`;

      this.$router.push({
        name: "BoardView",
        params: {
          company: companyKey,
          workspace: 1,
        },
      });

      setTimeout(() => {
        location.reload();
      }, 500);
    },
    signOutSpecificCompany(args) {
      this.folders &&
        this.folders.forEach((element) => {
          this.$store.dispatch("setFolderState", {
            folderId: element.id,
            flag: false,
          });
        });
      //this.$store.dispatch("removeFolderState", {});
      this.$store.dispatch("signoutFromCompany", args);
      this.unsubscribeAllChannel();
      this.$router.push({
        name: "SigninView",
      });
      resetState();
    },
    async readMessageNotifications(notification_ids) {
      if (notification_ids.length) {
        const variables = {
          notification_ids: notification_ids,
          read: true,
        };
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables,
        });
      }
    },
    async readBoardNotifications(notification_ids) {
      if (notification_ids.length) {
        const variables = {
          notification_ids: notification_ids,
          read: true,
        };
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables,
        });
      }
    },
    async exportTimeLogCsv() {
      if (this.getAllTimelogs.length) {
        const res = await exportTimeLogs(
          this.returnCurrentWorkspaceId,
          this.currentCompany.accessToken,
          0,
          true
        );
        if (res && res.status_code === 200) {
          window.location = res.data;
        }
      } else {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "There are no hours tracked.",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    closeFooterMenuDialog() {
      this.footerMenuDialog.show = false;
    },
    navigateTo(item) {
      this.footerMenuDialog.selectedMenuItem = item;
    },
  },
  computed: {
    checkBanner() {
      if (
        this.currentCompany &&
        this.currentCompany.status &&
        this.currentCompany.status.subscriptionOver &&
        this.currentCompany.status.trialOver &&
        this.isAdmin
      ) {
        return true;
      } else if (
        this.currentCompany &&
        this.currentCompany.status.trialOver &&
        this.company.subscription.plan_id === 1 &&
        this.isAdmin
      ) {
        return true;
      }
      return false;
    },
    ...mapGetters({
      subscriptionPlanData: "subscriptionPlanData",
    }),
    isAdmin() {
      return this.user && this.user.roles[0].label === "Admin";
      // return find(this.user.roles, o => o.name === "admin");
    },
    ...mapGetters({
      companies: "auth",
      ownCompany: "activeCompany",
    }),
    currentCompany() {
      if (this.companies && this.ownCompany) {
        return get(this.companies, this.ownCompany);
      } else {
        return null;
      }
    },
    onlineMembers() {
      return this.$store.state.auth.onlineMembers;
    },
    fullName() {
      if (this.user) return this.user.first + " " + this.user.last;
      return "";
    },
    activeCompany() {
      return get(this.companies, this.$route.params.company);
    },
    returnCurrentWorkspaceId() {
      return Number(
        this.$route.params.workspace && this.$route.params.workspace
      );
    },
    workspaces() {
      const query = this.$api.getQuery("Workspaces2Query");
      return query.data && query.data.workspaces2;
    },
    getAllTimelogs() {
      let allTimelogs = [];
      if (this.workspaces) {
        this.workspaces.forEach((element) => {
          if (element.timelogs) {
            element.timelogs.forEach((timelog) => {
              allTimelogs.push(timelog);
            });
          }
        });
      }
      return allTimelogs;
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspaceObj() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    // currentWorkspaceObj() {
    //   if (this.workspaces !== null || this.workspaces !== undefined) {
    //     return (
    //       this.workspaces !== null &&
    //       this.workspaces.find(
    //         (w) => w.id === Number(this.$route.params.workspace)
    //       )
    //     );
    //   } else if (this.workspaces === null || this.workspaces === undefined) {
    //     return this.workspaces.find((w) => w.id === 1 && w.is_general === 1);
    //   } else {
    //     return null;
    //   }
    // },
    isWorkspaceOwner() {
      if (this.currentWorkspaceObj && this.user) {
        return this.user.id === this.currentWorkspaceObj.owner.id;
      }
      return false;
    },
    companyMembers() {
      if (this.members) {
        return this.members.filter((member) => {
          let search = this.search.text
            ? this.search.text
                .replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1")
                .toLowerCase()
            : "";
          return (
            member.email.match(search) ||
            member.first.toLowerCase().match(search) ||
            member.last.toLowerCase().match(search)
          );
        });
      } else {
        return [];
      }
    },
    filteredWorkspaces() {
      if (!this.search.text) return this.workspaces;

      return (
        this.workspaces &&
        this.workspaces.filter((workspace) => {
          let search = this.search.text
            ? this.search.text
                .replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1")
                .toLowerCase()
            : "";
          return workspace.title.toLowerCase().match(search);
        })
      );
    },
    rootWorkspaces() {
      return this.filteredWorkspaces.filter(
        (workspace) => workspace.folder && workspace.folder.length === 0
      );
    },
    sortedWorkspaces() {
      const filteredWorkspaces =
        this.rootWorkspaces &&
        this.rootWorkspaces.filter(
          (w) =>
            !w.is_preferred && (w.type === "public" || w.type === "private")
        );
      filteredWorkspaces.sort((first, second) => {
        var nameA = first.title.toUpperCase();
        var nameB = second.title.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      return filteredWorkspaces;
    },
    peopleWorkspaces() {
      return (
        this.rootWorkspaces &&
        this.rootWorkspaces.filter(
          (w) => !w.is_preferred && w.type === "people"
        )
      );
    },
    preferredWorkspaces() {
      return (
        this.rootWorkspaces && this.rootWorkspaces.filter((w) => w.is_preferred)
      );
    },
    folders() {
      return this.folder;
    },
    folderWorkpaces() {
      return groupBy(this.filteredWorkspaces, (workspace) => {
        return workspace.folder[0] && workspace.folder[0].id;
      });
    },
    totalNotifications() {
      const query = this.$api.getQuery(`NotificationsQuery`);
      if (query.data) {
        const notifications = query.data.notifications.filter((n) => !n.read);
        return notifications.length;
      } else {
        return null;
      }
    },
    totalCollaborationNotifications() {
      const query = this.$api.getQuery(`NotificationsQuery`);
      if (query.data) {
        const notifications = query.data.notifications
          .filter((n) => !n.read)
          .filter((n) => n.action.object_type === "App\\Post");
        return notifications.length;
      } else {
        return null;
      }
    },
    totalBoardNotifications() {
      const query = this.$api.getQuery(`NotificationsQuery`);
      if (query.data) {
        const notifications = query.data.notifications
          .filter((n) => !n.read)
          .filter(
            (n) =>
              n.action.type === "task-comment-created" ||
              n.action.type === "task-member-attached" ||
              n.action.type === "subtask-member-attached" ||
              n.action.type === "task-comment-reaction-added"
          );
        return notifications.length;
      } else {
        return null;
      }
    },
    userProfilePhoto() {
      if (this.user && this.user.pic) {
        return this.user.pic;
      } else {
        return null;
      }
    },
    nameInitials() {
      if (this.user) {
        return (
          this.user.first.charAt(0).toUpperCase() +
          "" +
          this.user.last.charAt(0).toUpperCase()
        );
      } else {
        return null;
      }
    },
    currentRole() {
      if (this.user) {
        return this.user.roles[0].id;
      }
      return 4;
    },
    activeTimelog() {
      if (this.activeTaskId) {
        const taskEntity = this.$api.getEntity("task", this.activeTaskId);
        return taskEntity && taskEntity.activetimelog;
      }
      return null;
    },
    activeTaskId() {
      return this.company && this.company.start_task_id;
    },
    activeTimerTask() {
      const taskEntity = this.$api.getEntity("task", this.activeTaskId);
      return taskEntity;
    },
    imageList() {
      if (this.media) {
        return uniqBy(orderBy(this.media, ["id"], ["desc"]), "id");
      } else {
        return [];
      }
    },
    activeMyTeamsMemberId() {
      if (this.$route.params.userId) {
        return parseInt(this.$route.params.userId);
      }
      return null;
    },
    isAuthUserMyTaskView() {
      return (
        this.activeMyTeamsMemberId &&
        this.activeMyTeamsMemberId === this.user.id
      );
    },
  },
  watch: {
    "createWorkspace.show"(e) {
      this.closeModal("createWorkspace.show", e);
    },
    invitepeopleDialog(e) {
      this.closeModal("invitepeopleDialog", e);
    },
    workspaces(value) {
      if (value !== "") {
        setTimeout(() => {
          var container = document.getElementById("sidebar-scroll-container");
          if (container) {
            container.scrollTop = 2;
            container.classList.add("show-persistant");
          }
        }, 500);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.add-people {
  font-family: Avenir Next;
  color: #42526e;
  font-size: 15px;
  font-weight: 600;
  // border: 2px solid #cce4ff;
  // border-radius: 5px;
  padding: 5px 10px;

  // &:hover {
  //   color: #42526E !important;
  //   background-color: #007aff;
  // }
}
.is-electron .electron-spacer {
  user-select: none;
  position: relative;
  height: 29px;
  margin-bottom: -16px;
}
.learn-more {
  font-size: 10px !important;
  color: white;
  background: #a4aeb8;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  &:hover {
    background-color: #007aff;
  }
}
</style>
